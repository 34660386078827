import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfile } from '../../../models/userProfilResponceDTO';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environement';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class ProfilService {
  profilUrl = environment.apiUrl+"/profil";

  constructor(private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object

  ) { }

  private getAuthHeaders(): { [header: string]: string } {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('presence');
      return token ? { Authorization: `Bearer ${token}` } : {};
    }
    return {};
  }

  getProfilById(id: number): Observable<any> {
    return this.http.get(`${this.profilUrl}/${id}`, {
      headers: this.getAuthHeaders(),
    });
  }

  updateProfil(id: number, profilData: any): Observable<any> {
    return this.http.put(`${this.profilUrl}?id=${id}`, profilData , {
      headers: this.getAuthHeaders(),
    });
  }
}
